<template>
    <v-app>
        <v-layout>
            <v-app-bar color="blue-grey-darken-1" title="FIBO 369">
            </v-app-bar>

            <v-main style="min-height: 300px;">

                <v-alert color="white ma-2 pb-0 text-h5" border="start">
                    <v-textarea
                        rows="3"
                        v-model="text"
                        label="Введите текст на русском и/или цифры"
                        class="text-h5"
                        required
                        autofocus
                    >
                    </v-textarea>
                </v-alert>

                <div class="text-grey">
                    <v-alert
                            class="ma-2 text-h5"
                            color="green-lighten-5"
                            border="start"
                    >
                        {{ text }}
                        <v-chip color="grey" class="text-h5" label size="x-large" v-if="text"> = </v-chip>
                        <v-chip class="ma-2 text-h5" color="green" label size="x-large">
                            {{ getFibonacciCode() }}
                        </v-chip>
                    </v-alert>

                    <v-chip class="ma-2" color="green" label size="x-large" v-if="calculation">{{ calculation }}</v-chip>
                </div>
            </v-main>
        </v-layout>
    </v-app>
</template>

<script>

export default {
    name: 'App',
    data: () => ({
        text: '',
        calculation: '',
        alphabet: new Map([
            ["а", 1],
            ["б", 2],
            ["в", 3],
            ["г", 4],
            ["д", 5],
            ["е", 6],
            ["ё", 7],
            ["ж", 8],
            ["з", 9],
            ["и", 1],
            ["й", 2],
            ["к", 3],
            ["л", 4],
            ["м", 5],
            ["н", 6],
            ["о", 7],
            ["п", 8],
            ["р", 9],
            ["с", 1],
            ["т", 2],
            ["у", 3],
            ["ф", 4],
            ["х", 5],
            ["ц", 6],
            ["ч", 7],
            ["ш", 8],
            ["щ", 9],
            ["ъ", 1],
            ["ы", 2],
            ["ь", 3],
            ["э", 4],
            ["ю", 5],
            ["я", 6]
        ])
    }),
    methods: {
        getFibonacciCode() {
            let i = 0;
            let code = 0;
            let currentString
            //let calculation = ''

            currentString = this.text.toLowerCase()
            while (i < currentString.length) {
                let currentNumberOfSymbol = this.alphabet.get(currentString[i])
                console.log(currentString[i])
                if (typeof currentNumberOfSymbol !== 'undefined') {
                    code = code + currentNumberOfSymbol
                    console.log(currentNumberOfSymbol.toString() + ' - ' + this.calculation)
                    //calculation += currentNumberOfSymbol.toString() + ' + '
                } else if (!isNaN(currentString[i])) {
                    code = code + Number(currentString[i])
                    //calculation += currentString[i] + ' + '
                }
                i = i + 1;
            }

            //this.calculation = calculation.trim().slice(0, -1) + ' = ' + code

            if (code > 9) {
                code = this.getSum(code)
            }

            return code
        },
        getSum(number) {
            let i = 0;
            let numbersString
            let resultSum = 0
            //let calculation = ' = '

            numbersString = number.toString()

            while (i < numbersString.length) {
                resultSum = resultSum + Number(numbersString[i])
                //calculation += numbersString[i] + ' + '
                i = i + 1;
            }

            //this.calculation += calculation.trimEnd().slice(0, -1) + ' = ' + resultSum

            if (resultSum > 9) {
                return this.getSum(resultSum)
            } else {
                return resultSum
            }
        },
    },
    computed: {}
}
</script>

<style>
.v-textarea {font-size: 26pt}
</style>